import React from 'react'
import { useRouter } from 'next/router'

import ThemeSwitch from './theme-switch'

import Menu from '@blocks/navigation/menu'
import Newsletter from '@modules/newsletter'
import Icon from '@components/icon'
import { formatAlternateUrl } from '@components/link'

const Footer = ({ data = {} }) => {
  const { blocks } = data
  if (!blocks.length) return null
  const router = useRouter()
  const friendlyLocale = {
    ja: '日本語',
    en: 'English',
  }

  return (
    <footer className="footer" role="contentinfo">
      <div className="footer--grid">
        {blocks.map((block, key) => (
          <div key={key} className="footer--block">
            {block.title && <strong>{block.title}</strong>}

            {block.menu?.items && (
              <Menu items={block.menu.items} className="menu-footer" />
            )}

            {block.newsletter && <Newsletter data={block.newsletter} />}

            {block.social && (
              <div className="menu-social">
                {block.social.map((link, key) => {
                  return (
                    <a key={key} href={link.url} target="_blank">
                      <Icon name={link.icon} />
                    </a>
                  )
                })}
              </div>
            )}

            {key === 3 && (
              <div className="footer--locale">
                {router.locales
                  .filter((l) => l !== router.locale)
                  .map((locale) => (
                    <a key={locale} href={formatAlternateUrl(locale, router)}>
                      {friendlyLocale[locale]}
                    </a>
                  ))}
              </div>
            )}
            {/* Put our extras in the last block */}
            {key === 3 && (
              <div className="footer--extras">
                {/* <ThemeSwitch /> */}

                <div className="footer--disclaimer">
                  <a
                    href="https://longforme.com"
                    className="footer--by-longforme"
                  >
                    <img
                      src="/long-forme-logo.svg"
                      height="20px"
                      alt="合同会社Long Forme"
                    />
                  </a>
                  <a href="https://directories.onepercentfortheplanet.org/profile/long-forme">
                    <img src="/one-percent-for-the-planet.svg" height="20px" />
                  </a>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </footer>
  )
}

export default Footer
